/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
	.bone {
		position: relative;
		width: 360px;
	}
	.s1,
	.s2,
	.s3,
	.s4 {
		background: #fff;
		height: 100px;
		width: 100px;
		border: 5px solid #63c132;
		border-radius: 200px;
		position: absolute;
	}
	.s1 {
		left: 0;
		top: 100px;
	}
	.s2 {
		right: 0;
		top: 100px;
	}
	.s3 {
		top: 205px;
	}
	.s4 {
		right: 0;
		top: 205px;
	}
	.centerbone {
		height: 100px;
		width: 150px;
		border: 5px solid #63c132;
		position: absolute;
		left: 105px;
		top: 150px;
		border-width: 5px 0 5px;
	}
	.clean {
		background: #fff;
		width: 250px;
		height: 100%;
		position: absolute;
		left: -45px;
		text-align: center;
	}
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) and (max-width: 960px) {
	.bone {
		position: relative;
		width: 500px;
	}
	.s1,
	.s2,
	.s3,
	.s4 {
		background: #fff;
		height: 150px;
		width: 150px;
		border: 5px solid #63c132;
		border-radius: 200px;
		position: absolute;
	}
	.s1 {
		left: 0;
		top: 50px;
	}
	.s2 {
		right: 0;
		top: 50px;
	}
	.s3 {
		top: 205px;
	}
	.s4 {
		right: 0;
		top: 205px;
	}
	.centerbone {
		height: 150px;
		width: 190px;
		border: 5px solid #63c132;
		position: absolute;
		left: 155px;
		top: 130px;
		border-width: 5px 0 5px;
	}
	.clean {
		background: #fff;
		width: 340px;
		height: 100%;
		position: absolute;
		left: -70px;
		text-align: center;
	}
}

/* Medium devices (landscape tablets) */
@media only screen and (min-width: 960px) and (max-width: 1280px) {
	.bone {
		position: relative;
		width: 600px;
	}
	.s1,
	.s2,
	.s3,
	.s4 {
		background: #fff;
		height: 180px;
		width: 180px;
		border: 5px solid #63c132;
		border-radius: 200px;
		position: absolute;
	}
	.s1 {
		left: 0;
		top: 20px;
	}
	.s2 {
		right: 0;
		top: 20px;
	}
	.s3 {
		top: 205px;
	}
	.s4 {
		right: 0;
		top: 205px;
	}
	.centerbone {
		height: 170px;
		width: 230px;
		border: 5px solid #63c132;
		position: absolute;
		left: 185px;
		top: 120px;
		border-width: 5px 0 5px;
	}
	.clean {
		background: #fff;
		width: 410px;
		height: 100%;
		position: absolute;
		left: -90px;
		text-align: center;
	}
}

/* Large devices (laptops/desktops) */
@media only screen and (min-width: 1280px) and (max-width: 1920px) {
	.bone {
		position: relative;
		width: 800px;
	}

	.s1,
	.s2,
	.s3,
	.s4 {
		background: #fff;
		height: 200px;
		width: 200px;
		border: 5px solid #63c132;
		border-radius: 200px;
		position: absolute;
	}
	.s1 {
		left: 0;
	}
	.s2 {
		right: 0;
	}
	.s3 {
		top: 205px;
	}
	.s4 {
		right: 0;
		top: 205px;
	}
	.centerbone {
		height: 250px;
		width: 390px;
		border: 5px solid #63c132;
		position: absolute;
		left: 205px;
		top: 78px;
		border-width: 5px 0 5px;
	}
	.clean {
		background: white;
		width: 600px;
		height: 100%;
		position: absolute;
		left: -104px;
		text-align: center;
	}
}

/* Extra large devices (large laptops and desktops) */
@media only screen and (min-width: 1920px) {
	.bone {
		position: relative;
		width: 800px;
	}
	.s1,
	.s2,
	.s3,
	.s4 {
		background: #fff;
		height: 200px;
		width: 200px;
		border: 5px solid #63c132;
		border-radius: 200px;
		position: absolute;
	}
	.s1 {
		left: 0;
	}
	.s2 {
		right: 0;
	}
	.s3 {
		top: 205px;
	}
	.s4 {
		right: 0;
		top: 205px;
	}
	.centerbone {
		height: 250px;
		width: 390px;
		border: 5px solid #63c132;
		position: absolute;
		left: 205px;
		top: 78px;
		border-width: 5px 0 5px;
	}
	.clean {
		background: #fff;
		width: 600px;
		height: 100%;
		position: absolute;
		left: -104px;
		text-align: center;
	}
}
