.pagination {
	margin: 15px auto;
	display: flex;
	list-style: none;
	outline: none;
	border-radius: 50px;
	font-size: 18px;
}
.pagination > .active > a {
	background-color: #63c132;
	border-color: #63c132;
	color: #fff;
}
/* contenedor de botones números */
.pagination > li > a {
	border-radius: 5px;
	padding: 5px 10px;
	outline: none;
	cursor: pointer;
	color: #fff;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
	background-color: #63c132;
	outline: none;
}
.pagination > li > a,
.pagination > li > span {
	color: #fff;
}
/* contenedor atrás y siguiente */
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
	border-radius: 5px;
	background-color: #63c132;
	color: #fff;
	margin-right: 20px;
	margin-left: 20px;
}

@media (max-width: 600px) {
	.pagination > li:first-child > a {
		margin-right: 20px;
		margin-left: 0px;
	}
	.pagination > li:last-child > a {
		margin-right: 0px;
		margin-left: 20px;
	}
}

@media (max-width: 350px) {
	.pagination > li:first-child > a {
		margin-right: 5px;
		margin-left: 0px;
	}
	.pagination > li:last-child > a {
		margin-right: 0px;
		margin-left: 5px;
	}
}
