/* Quitar bordes de botones */

body {
	background-color: #f0f2f5;
}

button {
	outline: none !important;
	text-transform: none !important;
}

#buttonWhite {
	color: #fff !important;
}

.swal-wide {
	z-index: 3 !important;
}

/* * {
	user-select: none;
} */

*::-webkit-scrollbar {
	width: 15px;
	height: 12px;
}

*::-webkit-scrollbar-thumb {
	background-color: #63c132;
	border-left: 2px solid white;
}

*::-webkit-scrollbar-thumb:hover {
	background-color: #54a32a;
}

@media only screen and (max-width: 600px) {
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
}

.react-tel-input .special-label {
	left: 10px !important;
}
